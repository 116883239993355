import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "formulario",
      "style": {
        "position": "relative"
      }
    }}>{`Formulário`}</h1>
    <p>{`Formulários são usados para submeter dados. Todo campo deve vir acompanhado de uma descrição.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`É recomendável que a validação dos dados ocorra antes do usuário submeter o formulário, sempre que possível.`}</li>
      <li parentName="ul">{`Utilize `}<em parentName="li">{`placeholders`}</em>{` para auxiliar na entrada de campos numéricos. Os `}<em parentName="li">{`placeholders`}</em>{` ficam visíveis até que o usuário inicie a digitação.`}</li>
      <li parentName="ul">{`Utilize máscaras que ajudem o usuário a identificar o padrão do `}<em parentName="li">{`input`}</em>{`.`}</li>
      <li parentName="ul"><em parentName="li">{`Inputs`}</em>{` obrigatórios devem estar sinalizados com um asterisco vermelho após a descrição.`}</li>
    </ul>
    <h2 {...{
      "id": "exemplo",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo`}</h2>
    <p>{`O exemplo a seguir não usa nenhuma biblioteca para gerenciar o estado do formulário. Na prática, você pode (mas não precisa) usar uma biblioteca de terceiros para gerenciar o estado do formulário, como `}<a parentName="p" {...{
        "href": "https://github.com/wsmd/react-use-form-state"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-use-form-state`}</code></a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/final-form/react-final-form"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-final-form`}</code></a>{`, `}<a parentName="p" {...{
        "href": "https://github.com/jaredpalmer/formik"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`formik`}</code></a>{`, entre outros.`}</p>
    <Demo src='pages/components/form/Form' mdxType="Demo" />
    <h2 {...{
      "id": "exemplo-com-react-use-form-state",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo com `}<a parentName="h2" {...{
        "href": "https://github.com/wsmd/react-use-form-state"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-use-form-state`}</code></a></h2>
    <iframe src='https://codesandbox.io/embed/bold-form-example-use-form-state-s0ptf?autoresize=1&fontsize=14&module=%2Fsrc%2FForm.tsx&view=preview' title='bold-form-example-use-form-state' allow='geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media' style={{
      width: '100%',
      height: '500px',
      border: 0,
      borderRadius: '4px',
      overflow: 'hidden',
      marginBottom: '2rem'
    }} sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>
    <h2 {...{
      "id": "exemplo-com-react-final-form",
      "style": {
        "position": "relative"
      }
    }}>{`Exemplo com `}<a parentName="h2" {...{
        "href": "https://github.com/final-form/react-final-form"
      }}><code parentName="a" {...{
          "className": "language-text"
        }}>{`react-final-form`}</code></a></h2>
    <iframe src='https://codesandbox.io/embed/bold-form-example-final-form-8pzzd?autoresize=1&fontsize=14&module=%2Fsrc%2FFormExample.tsx&view=preview' title='bold-form-example-final-form' allow='geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media' style={{
      width: '100%',
      height: '500px',
      border: 0,
      borderRadius: '4px',
      overflow: 'hidden',
      marginBottom: '2rem'
    }} sandbox='allow-modals allow-forms allow-popups allow-scripts allow-same-origin'></iframe>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      